<template>
  <div :class="{ 'c-dark-theme': $store.state.darkMode, animated: false, fadeIn: true }">
    <BRow v-if="tank" class="mt-1">
      <BCol class="border-dark font-2xl font-weight-bolder mr-1">
        <BBadge class="mr-1" variant="dark">{{ areaMap[tank.arCode] }}</BBadge>
        <BBadge class="mr-1" variant="dark">{{ tank.name }}</BBadge>
        <BBadge class="mr-1" variant="dark">{{tank.tankCode}}</BBadge>
        <BBadge v-if="pk" :variant="IoStsVariant[pk.sts.io]">{{ FuncCodeMap[pk.fcd] }}</BBadge>
        <div class="float-right">
        <BButton @click="toggleUnit" class="small font-weight-bold" size="sm" variant="outline-dark"><b>단위 변경</b></BButton>
        </div>
      </BCol>
      <BCol class="border-dark p-1">
        <BProgress :max="100" height="2rem">
          <BProgressBar class="text-dark font-weight-bold font-2xl"
                        :value="tank.volPercent"
                        :label="`${tank.volPercent} %`"
                        :animated="true"
                        :variant="progressVariant(tank.volPercent)" />
        </BProgress>
      </BCol>
    </BRow>

    <BRow class="border-secondary mt-1">
      <BCol>
        <BTableSimple small class="text-nowrap text-right font-weight-bolder" v-if="tank">
          <BTr>
            <BTd> <BBadge class="font-lg">용도</BBadge> </BTd>
            <BTd>
              <BBadge variant="info" class="font-lg">{{ tankUseMap[tank.tankUse] }}</BBadge>
            </BTd>
          </BTr>
          <BTr v-if="tank.tankShape==='4' && pk">
            <BTd><BBadge class="font-lg">상태 정보</BBadge></BTd>
            <BTd>
              <BBadge class="font-weight-bold font-lg" :variant="IoStsVariant[pk.sts.io]">{{FuncCodeMap[pk.fcd]}}</BBadge>
              <div v-if="pk.fcd==='02'||pk.fcd==='03'||pk.fcd==='04'" class="float-right">
                <BBadge class="font-weight-bold font-lg ml-1" variant="info"> {{ EqCodeMap[pk.eqcd] }} </BBadge> <BBadge> {{ pk.eqid }} </BBadge>
              </div>
            </BTd>
          </BTr>
          <BTr v-if="tank.tankShape==='4'">
            <BTd> <BBadge class="font-lg">운행 정보</BBadge></BTd>
            <BTd class="font-weight-bold">
              <BBadge variant="info" v-show="opInfo.totalOpMin" >
                {{ toHoursAndMinutes(Math.round(opInfo.totalOpMin)) }}
              </BBadge>
              <BBadge variant="primary" class="font-lg ml-1">{{ opInfo.totalDistKm.toFixed(2) }} <small>km</small></BBadge>
            </BTd>
          </BTr>

          <BTr v-if="tank.tankShape!=='4'" class="font-lg">
            <BTd> <BBadge class="font-lg">탱크형태</BBadge> </BTd>
            <BTd class="small">
              <BBadge variant="info" class="font-lg">{{ shapeMap[tank.tankShape] }}</BBadge>
            </BTd>
          </BTr>
          <BTr>
            <BTd> <BBadge class="font-lg">저장능력</BBadge> </BTd>
            <BTd>
              <BBadge variant="info" class="font-lg">{{ trans(tank.tankVolume, 'v', volUnit) }} <small>{{ unitSign(volUnit) }}</small></BBadge>
            </BTd>
          </BTr>
          <BTr>
            <BTd> <BBadge class="font-lg">실용량</BBadge> </BTd>
            <BTd>
              <BBadge variant="info" class="font-lg">
                {{trans(tank.tankRealVol, 'v', volUnit) }} <small>{{ unitSign(volUnit) }}</small>
              </BBadge>
            </BTd>
          </BTr>
          <BTr>
            <BTd> <BBadge class="font-lg">입고가능</BBadge> </BTd>
            <BTd>
              <BBadge variant="primary" class="font-lg">{{ trans(pk?.vf, 'v', volUnit) }} <small>{{ unitSign(volUnit) }}</small></BBadge>
            </BTd>
          </BTr>
          <BTr>
            <BTd> <BBadge class="font-lg">온도센서</BBadge></BTd>
            <BTd>
              <BBadge class="font-lg" variant="info">{{ tank.machine?tank.machine.numOfTemp:0 }}<small>개</small></BBadge>
            </BTd>
          </BTr>

        </BTableSimple>
        <BRow v-if="pk">
          <BCol class="text-left text-lg-center">
                        <span v-for="e of pk.events" :key="e.code">
                        <BBadge class="mr-1 font-xl" :variant="eventColorMap[e.code]">{{ eventCodeMap[e.code] }}</BBadge>
                        </span>
          </BCol>
        </BRow>
      </BCol>
      <BCol>
        <h5 v-if="!pk" class="text-center ml-2 mr-2 mt-lg-5 text-black-50 font-weight-bold text-justify"> 측정치 없음 </h5>
        <BTableSimple small class="text-dark text-nowrap text-right font-weight-bolder" v-if="pk && tank">
          <BTr>
            <BTd style="min-width:50px"> <BBadge class="font-lg">유위</BBadge> </BTd>
            <BTd style="min-width:70px"><BBadge class="font-lg" variant="primary">{{ trans(pk.ohr,'l',lenUnit) }} <small>{{ unitSign(lenUnit) }}</small> </BBadge></BTd>
          </BTr>
          <BTr v-show="tank.tankShape!=='4'">
            <BTd> <BBadge class="font-lg">수위</BBadge> </BTd>
            <BTd>
              <BBadge class="font-lg" variant="primary">
                {{ trans(pk.whr,'l',lenUnit) }} <small>{{ unitSign(lenUnit) }}</small>
              </BBadge>
            </BTd>
          </BTr>
          <BTr>
            <BTd> <BBadge class="font-lg">측정유량</BBadge>   </BTd>
            <BTd>
              <BBadge class="font-lg text-dark" variant="warning">
                {{ trans(pk.ovm, 'v', volUnit) }} <small>{{ unitSign(volUnit) }}</small>
              </BBadge>
            </BTd>
          </BTr>
          <BTr>
            <BTd> <BBadge class="font-lg">환산유량</BBadge> </BTd>
            <BTd>
              <BBadge class="font-lg" variant="info">
              {{ trans(pk.ovc,'v', volUnit) }} <small>{{ unitSign(volUnit) }}</small>
              </BBadge>
            </BTd>
          </BTr>
          <BTr>
            <BTd><BBadge class="font-lg">보정유량</BBadge> </BTd>
            <BTd>
              <BBadge class="font-lg" variant="info">
                {{ trans(pk.ovcr,'v', volUnit) }} <small>{{ unitSign(volUnit) }}</small>
              </BBadge>
            </BTd>
          </BTr>
          <BTr>
            <BTd> <BBadge class="font-lg">수분량</BBadge> </BTd>
            <BTd>
              <BBadge class="font-lg" variant="danger">
              {{ trans(pk.wv, 'v', volUnit) }} <small>{{ unitSign(volUnit) }}</small>
              </BBadge>
            </BTd>
          </BTr>
          <BTr>
            <BTd> <BBadge class="font-lg">평균온도</BBadge> </BTd>
            <BTd>
              <BBadge class="font-lg" variant="primary">
              {{ trans(pk.avgTm, 't', tmpUnit) }} <small>{{ unitSign(tmpUnit) }}</small>
              </BBadge>
            </BTd>
          </BTr>

        </BTableSimple>



      </BCol>
    </BRow>

  </div>
</template>
<script>


// import moment from 'moment';
// import qs from "querystring";
import {
  apiCall,
  comma,
  ltr2gal,
  trans,
  unitSign,
} from "@/common/utils";
import {IoStsMap, IoStsVariant, Colors, EqCodeMap, EventColorMap, FuncCodeMap} from "@/common/constants";
import store from "@/store";
// import TankLineChart from "@/views/components/charts/TankLineChart.vue";


export default {
  name: 'LayerPanel',


  components: {

  },

  props: {
    tank: {type: Object, default: null },
    pk: {type: Object, default: null },
  },
  data(){
    return {
      IoStsMap,
      IoStsVariant,
      eventColorMap: EventColorMap,
      FuncCodeMap,

      opInfo: { totalDistKm: 0 , totalOpMin: 0},
      comma: comma,
      Colors: Colors,
      areaMap: this.$store.state.area['map'],
      nozSizeMap: this.$store.state.codeMaps['NOZ_SIZE'],
      shapeMap: this.$store.state.codeMaps['TANK_SHAPE'],
      tankUseMap: this.$store.state.codeMaps['TANK_USE'],
      oilCodes: this.$store.state.codeMaps['OIL_CODE'],
      oilColors: this.$store.state.codeMaps['OIL_COLOR'],
      eventCodeMap: this.$store.state.codeMaps['EVENT'],

      trans: trans,
      unitSign: unitSign,
      ltr2gal: ltr2gal,
      EqCodeMap,

      volUnit: 'g', // l <--> g
      lenUnit: 'i', // mm <--> i
      tmpUnit: 'f', // c <---> f
      status: 'off', // norm, warn, info, off
      volPercent: 0,

      chartKey: 0,


    }
  },
  async created(){
    console.log( '--------------- LayerPanel created()  --------------- tank = ', this.tank);
    if(this.tank && this.tank.tankShape==='4'){
      await this.getLastHist();

    }

  },
  async mounted () {
    console.log( '--------------- LayerPanel mounted() ---------------');
  },
  methods: {

    progressVariant(v){
      if(!v) return 'dark';

      if(v<10) return 'info';
      else if(v < 40) return 'primary';
      else if(v < 80) return 'success';
      else if(v < 95) return 'warning';
      else if(v >= 95) return 'danger';
      else return 'info';
    },

    toggleUnit(){
      if(this.volUnit==='l') this.volUnit='g'
      else this.volUnit='l';

      if(this.lenUnit==='mm') this.lenUnit='i'
      else this.lenUnit='mm';

      if(this.tmpUnit==='c') this.tmpUnit = 'f';
      else this.tmpUnit='c';
    },

    /**
     *
     * @param tid
     * @returns {Promise<*>}
     */
    async getLastOperation(){
      try {
        const {result} = await apiCall('get', `/api/tank/lorry/operation/${this.tank.tid}`);
        this.opInfo = result;
      } catch (err){
        console.error(err);
      }
    },

    /**
     *
     * @param tid
     * @returns {Promise<*>}
     */
    async getLastHist(){
      try {
        const {result} = await apiCall('get', `/api/tank/lorry/hist/${this.tank.tid}`);
        this.opInfo = result;
      } catch (err){
        console.error(err);
      }
    },


  },

  watch: {
    /*tid: function(val){
      console.log( '--------------- child: watch( tankId ) LineByTank ---------------: '+val);
      this.renderLineChart();
    },
    toDts: function(val){
      console.log( '--------------- child: watch( searchDate ) LineByTank ---------------: '+val);
      this.renderLineChart();

    },*/
    // color: function(val){
    //   console.log( '--------------- child: watch( color ) LineByTank ---------------: '+val);
    // }
  }
}
</script>
